.edit {
}

.edit form {
  width: 90%;
  margin: 20px auto 0;
}

.edit label {
  display: block;
}

.edit input {
  width: 300px;
  display: block;
  margin: 5px 0 10px;
  border: none;
  line-height: 25px;
}

.edit input:focus {
  outline: none;
}

.edit .content {
  width: 100%;
  height: 400px;
  display: block;
  margin: 10px 0;
  border: none;
}

.edit .content:focus {
  outline: none;
}

.edit .markdown {
  width: 50%;
  margin: auto;
}

.edit .button {
  margin-top: 10px;
}
