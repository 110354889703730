:root {
  --primary: #419b4a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: var(--primary);
  text-align: center;
}

.next-page {
  width: fit-content;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: white;
  text-decoration: none;
}

.next-page:hover {
  cursor: pointer;
}

.next-page p {
  margin: 0;
  font-size: 20px;
  text-align: center;
}

.next-page .down-icon {
  display: block;
  margin: auto;
  font-size: 20px;
  text-align: center;
}

/* HomePage Start */

.home {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
}

.home .left {
  flex: 1;
  /* width: 50%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home .pic {
  width: 300px;
  max-width: 90%;
  box-shadow: 15px 15px var(--primary);
}

.home .right {
  flex: 1;
  /* width: 50%; */
  height: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home .intro {
  margin: 0;
  font-size: 80px;
  text-align: center;
}

.home .name {
  color: black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary);
}

.home .desc {
  font-size: 25px;
  text-align: center;
}

.home .not {
  color: var(--primary);
}

.home .link-container {
  width: 250px;
  display: flex;
  margin-bottom: 20px;
}

.home .link {
  flex: 1;
  margin: 0 10px;
  padding: 10px 0;
  text-align: center;
  font-size: 20px;
  text-decoration: none;
}

.home .resume {
  border: 1px solid white;
  color: white;
}

.home .blog {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: black;
}

.home .resume:hover {
  background-color: white;
  border-color: black;
  color: black;
}

.home .blog:hover {
  background-color: black;
  border-color: var(--primary);
  color: var(--primary);
}

.home .contact {
  margin-bottom: 50px;
}

.home .social-icon {
  margin: auto 10px 0;
  color: white;
  font-size: 35px;
}

@media (max-width: 900px) {
  .home {
    flex-direction: column;
  }
}
/* HomePage End */

/* AboutPage Start */

.about {
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-template-rows: auto auto;
}

.about .about-cont {
  margin-bottom: 50px;
}

.about .selling-pts {
  width: 70%;

  margin: 20px auto;
}

.about .selling-pts h2 {
  color: var(--primary);
  font-size: 30px;
  text-align: center;
}

.about .selling-pts .desc {
  color: #aaa;
  font-size: 20px;
}

.about .selling-pts .projects {
}

.about .selling-pts .projects-list {
  color: #aaa;
}

/* AboutPage End */